<template>
	<div id="disciplineUser">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">科组管理</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f">
					<div class="left">
						<!-- <div class="add u-f-justify el-icon-plus" @click="openDialog(1)"></div> -->
						<div class="building-list">
							<div v-for="(items, index) in sectionList" :label="items.name" :key="index" @click="leftTap(index)">
								<div class="u-f-item item u-f-jsb" :class="index == leftIndex ? 'active' : ''">
									<div class="u-f-item">
										<div style="cursor: pointer;">{{ items.name }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class=" right">
						<div class="title u-f-item u-f-jsb">
							<div>共{{ total }}条数据</div>
						</div>
						<el-table :data="peopleList" height="93%" ref="multipleTable" tooltip-effect="dark" style="width: 100%;border: 1px solid #EEEEEE;">
							<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
							<el-table-column label="序号" type="index" width="65" align="center"></el-table-column>
							<el-table-column prop="username" label="教师" align="center"></el-table-column>
							<el-table-column label="性别" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.gender == 1">男</span>
									<span v-if="scope.row.gender == 2">女</span>
									<span v-if="scope.row.gender == 3">未知</span>
								</template>
							</el-table-column>
							<el-table-column prop="department_name" label="科组长" align="center">
								<template slot-scope="scope">
									<el-switch :value ="scope.row.discipline_lead=='1'?true:false" @change="shezhi(scope.row)"></el-switch>
								</template>
							</el-table-column>
						</el-table>
						<div class="u-f-right" style="padding: 15px 0;">
							<!-- <el-button type="danger" plain size="mini" @click="delUser">删除</el-button> -->
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			upPad: false,
			mobile: '',
			newpassword: '',
			leftIndex: 0,
			condition: '',
			conditionList: [],
			sousuo: '',
			sectionList: [],
			editVisible: false,
			addVisible: false,
			total: 0,
			page: 1,
			limit: 13,
			name: '',
			pid: '',
			school_place_room_id: [],
			roomList: [],
			id: '',
			ids: [],
			userid: '',
			name: '',
			birthday: '',
			page: 1,
			sousuo: '',
			sousuo1: '',
			addVisible1: false,
			VisibleTitle: '添加人员',
			powerVisible: false,
			sex: 1,
			is_discipline: 1,
			is_department: 1,
			identity_type: 1,
			staff_type: 1,
			phone: '',
			identity: '',
			email: '',
			QQ: '',
			group_id: '',
			school_discipline_id: [],
			school_department_id: [],
			subjectList: [],
			sectionList: [],
			peopleList: [],
			roleList: [],
			type: 1,
			importVisible: false,
			file: ''
		};
	},
	mounted() {
		this.getSectionList();
	},
	methods: {
		leftTap(index) {
			this.leftIndex = index;
			this.page = 1;
			this.userList();
		},
		// 关
		shezhi(item){
			console.log(item)
			this.$api.setting.disciplinelead({
				discipline_id:item.discipline_id,
				user_id:item.id,
				type:item.department_lead=='1'?0:1
			}).then(res=>{
				if(res.data.code==1){
					this.$message.success('设置成功');
					this.userList()
				}else{
					this.$message.error(res.data.msg);
				}
			})
		},
		// 人员列表
		userList() {
			let data = {
				limit: this.limit,
				page: this.page
			};
			if (this.leftIndex > -1) {
				data.discipline_id = this.sectionList[this.leftIndex].id;
			}
			this.$api.setting.users(data).then(res => {
				if (res.data.code == 1) {
					this.peopleList = res.data.data.rows;
					this.total = res.data.data.total;
				}
			});
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.userList();
		},
		// 获取学科列表
		getSectionList() {
			this.$api.setting.subjectList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data;
					this.sectionList = data;
					this.userList();
				}
			});
		}
	}
};
</script>

<style lang="scss">
#disciplineUser {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 85vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 85vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 80vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.active {
					background-color: #e4f1ff;
					color: #007aff;
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 78vh;
			width: 100%;
			padding: 25px 50px 0 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.upload-file {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
